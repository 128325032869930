import React, { useState } from 'react';
import './App.css';
import BackgroundSlider from 'react-background-slider'

function App() {



  const [view, setView] = useState('empty');

  const viewSelection = (view) => {
    switch (view) {
      case 'empty':
        return <img src={'/logo.png'} height="50%" />
      case 'overOns':
        return <div className="content" onClick={() => setView('empty')}><div className="contentText">
          Wij zijn een groep geëngageerde jonge honden werkzaam in de ruimtelijke ordening.
          We hebben De Verbeeldstorm als non-profit opgericht om in onze vrije tijd onze verbeeldingsvaardigheden in te zetten voor het verder brengen van maatschappelijke discussies over de toekomst van Nederland.
          We werken graag in interdisciplinaire co-creatie met maatschappelijk betrokken partijen.  <br /> <br />
          Werk jij aan een wetenschappelijk, humanitair, educatief of ander maatschappelijk (onderzoeks)project dat verder kan worden geholpen met de kracht der verbeelding?
          Neem dan gerust contact met ons op om te kijken of we iets voor elkaar kunnen betekenen!
          </div></div>
      case 'contact':
        return <div className="content" onClick={() => setView('empty')}>
          <div className="contentText">e-mail: <br /> <a href="mailto:deverbeeldstorm@gmail.com">deverbeeldstorm@gmail.com</a></div>
        </div>
      default:
        return 'DEFAULT';
    }
  }


  return (
    <div className="App">
      <BackgroundSlider
        images={['/1.jpg', '/2.jpg', '/3.jpg', '/4.jpg', '/5.jpg', '/6.jpg', '/7.jpg', '/8.jpg', '/9.jpg']}
        duration={5} transition={2} />
      <div className="menu">
        <div className="menuItem" onClick={() => { if (view !== 'overOns') { setView('overOns') } else { setView('empty') }}}>
          OVER ONS
        </div>
        <div className="menuItem" onClick={() => { if (view !== 'contact') { setView('contact') } else { setView('empty') }}}>
          CONTACT
        </div>
      </div>

      {viewSelection(view)}

    </div>


  );
}

export default App;
